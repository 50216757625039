import { isSupportedBrowser } from '@/utils/supportedBrowser'

const unsupportedBrowserPage = 'unsupported-browser'

const checkBrowser = () => {
  if (!isSupportedBrowser() && !document.referrer?.includes(unsupportedBrowserPage)) {
    document.location.assign(`/${unsupportedBrowserPage}.html`)
  }
}

checkBrowser()
